import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap, { Expo } from 'gsap';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import PublishedKyotocityKyoceraMuseum from '../../components/published-kyotocity-kyocera-museum';
import PublishedKex from '../../components/published-kex';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/works/work.module.scss';
import MainVisualSp from '../../images/works/parasophia-2015/main_visual_sp.jpg';
import MainVisualPc from '../../images/works/parasophia-2015/main_visual_pc.jpg';

class Parasophia2015 extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const img = document.querySelectorAll('.init_a_img');
    setTimeout(() => {
      gsap.to(img, {
        duration: 1.5,
        scale: 1,
        ease: Expo.easeOut,
      });
    }, 1000);
    const header = document.querySelector('header');
    const visual = document.querySelector('.visual');
    const visualHeight = visual.clientHeight - 64;
    window.addEventListener('scroll', () => { this.changeHeader(header, visualHeight); });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => { this.changeHeader(); });
  }

  changeHeader = (e, height) => {
    if (window.pageYOffset > height) {
      e.classList.add('work');
    } else {
      e.classList.remove('work');
    }
  };

  details = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const detailsItem = document.querySelectorAll('.init_a_detailsItem');
      this.initAnimation.list(detailsItem);
      unobserve();
    }
  };

  challengesPoint = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isChallengesPoint = 'ChallengesPoint';
      const headingChallengesPoint = document.querySelector('.init_a_headingChallengesPoint');
      const challengesPointItem = document.querySelectorAll('.init_a_challengesPointItem');
      this.initAnimation.heading(headingChallengesPoint, isChallengesPoint);
      this.initAnimation.list(challengesPointItem);
      unobserve();
    }
  };

  subCut1 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut1_bg');
      const subCutImg = document.querySelector('.init_a_subCut1_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut2 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut2_bg');
      const subCutImg = document.querySelector('.init_a_subCut2_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut3 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut3_bg');
      const subCutImg = document.querySelector('.init_a_subCut3_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  approach = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isApproach = 'Approach';
      const headingApproach = document.querySelector('.init_a_headingApproach');
      const approachItem = document.querySelectorAll('.init_a_approachItem');
      this.initAnimation.heading(headingApproach, isApproach);
      this.initAnimation.list(approachItem);
      unobserve();
    }
  };

  projectTeam01 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isProjectTeam = 'ProjectTeam';
      const headingProjectTeam = document.querySelector('.init_a_headingProjectTeam');
      const projectTeamItem01 = document.querySelectorAll('.init_a_projectTeamItem01');
      this.initAnimation.heading(headingProjectTeam, isProjectTeam);
      this.initAnimation.list(projectTeamItem01);
      unobserve();
    }
  };

  projectTeam02 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const projectTeamItem02 = document.querySelectorAll('.init_a_projectTeamItem02');
      this.initAnimation.list(projectTeamItem02);
      unobserve();
    }
  };

  otherWorks = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isOtherWorks = 'OtherWorks';
      const headingOtherWorks = document.querySelector('.init_a_headingOtherWorks');
      this.initAnimation.heading(headingOtherWorks, isOtherWorks);
      unobserve();
    }
  };

  render() {
    const WorkQuery = graphql`
      {
        DeviceSp: file(relativePath: { eq: "works/parasophia-2015/device_sp.png" }){
          childImageSharp {
            fluid(maxWidth: 170, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        DevicePc: file(relativePath: { eq: "works/parasophia-2015/device_pc.png" }){
          childImageSharp {
            fluid(maxWidth: 715, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut1: file(relativePath: { eq: "works/parasophia-2015/subcut_1.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut2: file(relativePath: { eq: "works/parasophia-2015/subcut_2.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut3: file(relativePath: { eq: "works/parasophia-2015/subcut_3.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const optionsDetails = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const options = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={WorkQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header isWork transition={transition} current="works" />
              <main className={`work ${Style.item}`}>
                <div className={`visual ${Style.visual}`}>
                  <figure className={`init_a_visual ${Style.visualFigure}`}>
                    <img
                      className="spTabVDisBlock init_a_img"
                      src={MainVisualSp}
                      alt="PARASOPHIA: 京都国際現代芸術祭 サイト構築・運用"
                    />
                    <img
                      className="tabHPcDisBlock init_a_img"
                      src={MainVisualPc}
                      alt="PARASOPHIA: 京都国際現代芸術祭 サイト構築・運用"
                    />
                  </figure>
                  <figure className={Style.visualDevice}>
                    <Img
                      className="spTabVDisBlock"
                      fluid={data.DeviceSp.childImageSharp.fluid}
                      alt="PARASOPHIA: 京都国際現代芸術祭 サイト構築・運用"
                    />
                    <Img
                      className="tabHPcDisBlock"
                      fluid={data.DevicePc.childImageSharp.fluid}
                      alt="PARASOPHIA: 京都国際現代芸術祭 サイト構築・運用"
                    />
                  </figure>
                </div>
                <Observer {...optionsDetails} onChange={this.details}>
                  <section className={`details ${Style.details}`}>
                    <div className={`inner ${Style.inner}`}>
                      <div className={Style.block}>
                        <div className={Style.left}>
                          <p className={`init_a_detailsCategory_text ${Style.detailsCategory}`}>ウェブサイト構築・運用</p>
                          <h1 className={`init_a_detailsTitle_text ${Style.detailsTitle}`}>
                            PARASOPHIA:
                            <br />
                            京都国際現代芸術祭
                          </h1>
                          <p className={`init_a_detailsCaption_text ${Style.detailsCaption}`}>京都国際現代芸術祭組織委員会事務局</p>
                          <p className={`init_a_detailsItem ${Style.description}`}>「PARASOPHIA: 京都国際現代芸術祭2015」のティザーサイト、本サイト、アーカイブサイトの構築及び運用を行いました。</p>
                          <p className={`init_a_detailsItem ${Style.link}`}>
                            <a
                              href="http://www.parasophia.jp/2015/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              http://www.parasophia.jp/2015/
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 17 14"
                              >
                                <rect x="4.5" y="0.5" width="12" height="9" />
                                <path d="M16,1v8H5V1H16 M17,0H4v10h13V0L17,0z" />
                                <rect x="0.5" y="4.5" width="12" height="9" />
                                <path d="M12,5v8H1V5H12 M13,4H0v10h13V4L13,4z" />
                              </svg>
                            </a>
                          </p>
                        </div>
                        <div className={Style.info}>
                          <dl className="init_a_detailsItem">
                            <dt>メディア</dt>
                            <dd>イベントサイト</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>プロジェクト期間</dt>
                            <dd>2012年2月〜2015年10月</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>ソリューション</dt>
                            <dd>コンセプト設計／情報設計／コンテンツ企画／システム要件定義／アートディレクション／ビジュアルデザイン／プロジェクト管理／運用更新マニュアル作成／多言語対応：日本語、英語</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className="sectionContent">
                  <div className="inner">
                    <Observer {...options} onChange={this.challengesPoint}>
                      <div>
                        <h2 className="heading">
                          <span className="text textChallengesPoint">Overview</span>
                          <span className="clip clipChallengesPoint init_a_headingChallengesPoint">Overview</span>
                        </h2>
                        <p className="init_a_challengesPointItem sectionAddition">概要</p>
                        <div className={`init_a_challengesPointItem ${Style.text}`}>
                          <p>2015年に京都ではじめて開催された大規模な現代芸術の国際展「PARASOPHIA: 京都国際現代芸術祭2015」。日本全体が一種のアートフェス・ブームのような状況にあるなか、同芸術祭は、一過性のイベントではなく、これからの京都を文化の生産地および発信地としていくための「通過点」として位置付けられました。そのため、会期中の広報活動だけではなく、2年にわたる準備期間のプロセスの公開や、後の世代に資産として残すための活動のアーカイブ化などを含め、展覧会後も見据えて「PARASOPHIA」を発信することを目指しました。</p>
                        </div>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut1}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut1_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut1_img"
                          fluid={data.subCut1.childImageSharp.fluid}
                          alt="PARASOPHIA: 京都国際現代芸術祭 サイト構築・運用"
                        />
                      </figure>
                    </Observer>
                    <Observer {...options} onChange={this.subCut2}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut2_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut2_img"
                          fluid={data.subCut2.childImageSharp.fluid}
                          alt="PARASOPHIA: 京都国際現代芸術祭 サイト構築・運用"
                        />
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut3}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut3_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut3_img"
                          fluid={data.subCut3.childImageSharp.fluid}
                          alt="PARASOPHIA: 京都国際現代芸術祭 サイト構築・運用"
                        />
                      </div>
                    </Observer>
                  </div>
                </section>

                <Observer {...options} onChange={this.approach}>
                  <section className="sectionContent">
                    <div className="inner">
                      <h2 className="heading">
                        <span className="text textApproach">Approach</span>
                        <span className="clip clipApproach init_a_headingApproach">Approach</span>
                      </h2>
                      <p className="init_a_approachItem sectionAddition">アプローチ</p>
                      <div className={`init_a_approachItem ${Style.text}`}>
                        <p>展覧会の開催前、開催期間、開催後の3つのタームに分け、それぞれの期間に求められる機能に合わせて、計3つのウェブサイトを制作し、最終的に1つのサイトとして結合しました。</p>
                        <p>
                          1.  開催前：特設サイト（公開：2013年5月）
                          <br />
                          プロジェクトの準備期間における調査研究のプロセスを記録・共有することを重視し、リサーチ・プログラムが回を重ねるごとに、建築的に、重層的に、拡張していくウェブサイトを目指しました。
                        </p>
                        <p>
                          2. 開催期間：本サイト（公開：2014年10月）
                          <br />
                          本展の5ヵ月前、開催期間における情報発信の機能を軸とした本サイトを公開しました。アーティストやイベント情報の検索・ソート機能を充実させる一方で、過度な「わかりやすさ」をもつコンテンツは最小限に留めるなど、芸術や文化がもつ多元的なものの見方・捉え方を重視しました。さらに、支援者へのインタビュー記事によって「通過点」としての「PARASOPHIA」を発信。蓄積した経験を次世代へと繋ぐコンテンツ設計を意識しました。
                        </p>
                        <p>
                          3. 開催後：アーカイブサイト（公開：2015年10月）
                          <br />
                          本展終了後の2015年10月、構想段階から閉幕時までの一連の活動を時系列化したアーカイブサイトを公開しました。事務局が解散して以降も芸術文化の資産として残るサイトにしたいというご要望を受け、動的なCMSから静的なサイトへ移行。外部要因の影響を極力排除し、保存性を高める改修を行いました。 また同時に、特設サイト・本サイトの整理・格納作業も実施。約2年半にわたるプロジェクトの文脈を有機的に繋ぎました。
                        </p>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className={`sectionContent ${Style.projectTeam}`}>
                  <div className={`inner ${Style.inner}`}>
                    <Observer {...options} onChange={this.projectTeam01}>
                      <div>
                        <h2 className="heading">
                          <span className="text textProjectTeam">Project <br className="spDisBlock" />team</span>
                          <span className="clip clipProjectTeam init_a_headingProjectTeam">Project <br className="spDisBlock" />team</span>
                        </h2>
                        <p className="init_a_projectTeamItem01 sectionAddition">プロジェクトチームのご紹介</p>
                        <p className={`init_a_projectTeamItem01 ${Style.intro}`}>
                          競争から共創へ。
                          <br />
                          弊社では、クライアントと
                          <br className="spDisBlock" />
                          受託会社、制作会社と
                          <br />
                          外部パートナーといった
                          <br className="spDisBlock" />
                          垣根を越え、
                          <br />
                          プロジェクトごとに最適な
                          <br className="spDisBlock" />
                          チームを編成しています。
                        </p>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.projectTeam02}>
                      <div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">プロジェクト体制</h3>
                          <div className="init_a_projectTeamItem02">
                            <dl>
                              <dt>光川 貴浩</dt>
                              <dd>Strategy</dd>
                            </dl>
                            <dl>
                              <dt>吉田 健人</dt>
                              <dd>Design</dd>
                            </dl>
                          </div>
                        </div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">外部パートナー</h3>
                          <dl className="init_a_projectTeamItem02">
                            <dt>so design 勤息 義隆</dt>
                          </dl>
                          <dl className="init_a_projectTeamItem02">
                            <dt>株式会社シーズ</dt>
                          </dl>
                        </div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">クライアント</h3>
                          <p className={`init_a_projectTeamItem02 ${Style.client}`}>京都国際現代芸術祭組織委員会事務局</p>
                        </div>
                      </div>
                    </Observer>
                  </div>
                </section>
                <Observer {...options} onChange={this.otherWorks}>
                  <section className={`sectionContent ${Style.otherWorks}`}>
                    <h2 className="heading">
                      <span className="text textOtherWorks">Other <br className="spDisBlock" />works</span>
                      <span className="clip clipOtherWorks init_a_headingOtherWorks">Other <br className="spDisBlock" />works</span>
                    </h2>
                    <PublishedKyotocityKyoceraMuseum transition={transition} />
                    <PublishedKex transition={transition} />
                    <p className={`btn ${Style.btn}`}>
                      <TransitionLink to="/works" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                        <span>Explore our works</span>
                      </TransitionLink>
                    </p>
                  </section>
                </Observer>
              </main>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Parasophia2015;
